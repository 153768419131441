import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_ADEPT_DATA, ADMIN_MEDICAL_CARDS_MANAGE } from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_MEDICAL_CARD } from 'Consts/activityLogs';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import MedicalPanel from 'Components/admin/medical/Panel';
import { TAB_MEDICAL_CARDS } from 'Components/pages/admin/AdeptData/component';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';

export default class AdminMedicalCardsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history, match } = this.props;
        const { params } = match;
        return (
            <StyledComponent
                className="admin-medical-cards-manage"
                styles={require('./styles')}
            >
                <Page>
                    <PageContent
                        breadcrumbs={[{
                            to: withVariables(ADMIN_ADEPT_DATA.path),
                            label: 'Kartoteka',
                        }, {
                            to: withVariables(ADMIN_ADEPT_DATA.path, {}, { tab: TAB_MEDICAL_CARDS }),
                            label: 'Wywiady medyczne',
                        }, {
                            to: withVariables(ADMIN_MEDICAL_CARDS_MANAGE.path, { id: params.id }, { tab: TAB_DATA }),
                            label: 'Szczegóły wywiadu medycznego',
                        }]}
                    >
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Wywiad medyczny"
                            tabs={[{
                                key: TAB_DATA,
                                label: 'Dane',
                                children: (
                                    <MedicalPanel 
                                        predefinedQuery={{
                                            id: params.id,
                                        }}
                                    />
                                ),
                            }, {
                                visible: true,
                                key: TAB_LOGS,
                                label: 'Logi',
                                children: (
                                    <LogsList 
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            dataType: ACTIVITY_LOGS_DATA_TYPE_MEDICAL_CARD,
                                            dataId: params?.data?.id || undefined,
                                        }}
                                        onMapFilters={() => []}
                                    />
                                ),
                            }]}
                        />
                    </PageContent>   
                </Page>    
            </StyledComponent>
        );
    }
}


import { connect } from 'react-redux';
import Component from './component';

import { single } from 'Redux/modules/admin/medicalCards/actions';

export default connect(
    state => ({
        medicalCard: state.adminMedicalCards.single,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    })
)(Component);
import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .no-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 6em 0;

        .headline {
            font-size: 2em;
            color: #393939;
            text-align: center;
        }
        .control {
            margin-top: 1em;
        }
    }
    .card-container {
        width: 100%;

        .card-headline {
            color: #393939;
            font-size: 2em;
            font-weight: 600;
            margin-bottom: 1em;
        }

        .categories {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            .category {
                width: 100%;
                margin-bottom: 2em;

                .category-headline {
                    color: #393939;
                    font-size: 1.6em;
                    margin-bottom: 1em;
                    padding-bottom: 1em;
                    border-bottom: 1px solid #CCCCCC;
                }
                .category-answers {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;

                    .answer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 1.2em;
                        width: 100%;
                        margin-bottom: .5em;
                        padding-bottom: .5em;
                        border-bottom: 1px dashed #666666;

                        .answer-question {
                            color: #7a7a7a;
                        }

                        .answer-value {
                            color: #454545;
                            text-align: right;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            flex-direction: column;

                            .answer-value-text {
                                margin-top: .5em;
                                font-size: .9em;
                                color: #666666;

                                span:nth-of-type(2) {
                                    font-weight: bold;
                                }
                            }

                            .answer-value-bool {
                                &.isTrue {
                                    color: red;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;